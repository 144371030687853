import React from "react";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-col col-md-5">
              <h6 style={{fontWeight: 700}}>About MediPuzzle</h6>
              <p className="p-small" style={{ paddingRight: 30 }}>
                A portal to help students learn medicine and professionals
                update new knowledge by playing various games created and
                curated by the subject experts.
              </p>
            </div>
            <div className="footer-col col-md-4">
              <h6 style={{fontWeight: 700}}>Links</h6>
              <ul className="list-unstyled li-space-lg p-small">
                <li>
                  <span style={{fontWeight: 600}}>Contact No:{" "}</span>
                  <a href="tel: +9779851171649">(+977)-9851171649</a>,&nbsp;
                  <a href="tel: +9779851191168">(+977)-9851191168</a>
                </li>
                <li>
                <span style={{fontWeight: 600}}>Email:{" "}</span>
                  <a href="mailto: learnviagames@gmail.com">
                    learnviagames@gmail.com
                  </a>
                </li>
                <li>
                  <a href="terms">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href="privacy">Privacy Policy</a>
                </li>
                <li>
                  <a style={{ color: "#e11d48" }} href="delete-account">
                    Delete Account
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-col col-md-3 third">
              <span className="fa-stack">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/Medipuzzle">
                  <i className="fas fa-circle fa-stack-2x" />
                  <i className="fab fa-facebook-f fa-stack-1x" />
                </a>
              </span>
              <span className="fa-stack">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/medipuzzle.app/">
                  <i className="fas fa-circle fa-stack-2x" />
                  <i className="fab fa-instagram fa-stack-1x" />
                </a>
              </span>
              <span className="fa-stack">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/medipuzzle_app">
                  <i className="fas fa-circle fa-stack-2x" />
                  <i className="fab fa-twitter fa-stack-1x" />
                </a>
              </span>
              <span className="fa-stack">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.pinterest.com/medipuzzle/">
                  <i className="fas fa-circle fa-stack-2x" />
                  <i className="fab fa-pinterest-p fa-stack-1x" />
                </a>
              </span>
              <p className="p-small">
                We would love to hear from you{" "}
                <a href="mailto:ayata.inc@gmail.com">
                  <strong>ayata.inc@gmail.com</strong>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="fs-6 my-3 text-muted">
                Copyright © {currentYear} Medipuzzle, <i>Version 1.2</i>{" "}
                Reproduction without explicit permission is prohibited. All
                Rights Reserved. Powered By{" "}
                <a href="https://www.ayata.com.np">Ayata Inc.</a> and Subsidiary
                of <a href="https://avyaas.com">Avyaas</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
